<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <notifications group="notification" position="top right" />
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <inline-svg src="media/svg/icons/Map/Position.svg" />
                </span>
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/Lessors/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                All Lessors
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="patchEntity"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  Update Lessor
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
                  <div class="form-group row">
                    <label class="col-3">Name:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.name"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Company:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.company"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Address:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.address"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">OIB:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.oib"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Email:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.email"
                        class="form-control form-control-solid"
                        type="email"
                      />
                    </div>
                  </div>
                  <h3 class="text-dark font-weight-bold mb-10">Meta:</h3>
                  <div class="form-group row">
                    <label class="col-3">Rental type:</label>
                    <div class="col-9">
                      <select
                        @change="onRentalTypeChange($event)"
                        class="form-control"
                      >
                        <option>Fix</option>
                        <option>Percentage</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Contract rental amount:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.rentalAmount"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Pdv:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.pdv"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Contract start date:</label>
                    <DatePicker
                      class="col-9"
                      v-model="lessor.meta.contractStartDate"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <input
                          @click="togglePopover()"
                          :value="inputValue"
                          class="form-control form-control-solid"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Contract end date:</label>
                    <DatePicker
                      class="col-9"
                      v-model="lessor.meta.contractEndDate"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <input
                          @click="togglePopover()"
                          :value="inputValue"
                          class="form-control form-control-solid"
                          readonly
                        />
                      </template>
                    </DatePicker>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Payment insurance:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.paymentInsurance"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Devices number:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.devicesNumber"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3">Notification Email:</label>
                    <div class="col-9">
                      <input
                        v-model="lessor.meta.notificationEmail"
                        class="form-control form-control-solid"
                        type="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'LessorsEdit',
  components: {
    DatePicker,
  },
  data() {
    return {
      lessor: {
        name: null,
        company: null,
        address: null,
        oib: null,
        email: null,
        meta: {
          rentalType: null,
          contractStartDate: null,
          contractEndDate: null,
          rentalAmount: null,
          devicesNumber: null,
          pdv: null,
          notificationEmail: null,
          paymentInsurance: null,
        },
      },
      date: null,
      masks: {
        input: 'dd.MM.YYYY.',
      },
    }
  },
  mounted() {
    ApiService.get('lessors', this.$route.params.id).then(({ data }) => {
      this.lessor.name = data.name
      this.lessor.company = data.company
      this.lessor.address = data.address
      this.lessor.oib = data.oib
      this.lessor.email = data.email
      this.lessor.meta.rentalType = data.meta.rentalType
      this.lessor.meta.contractStartDate = data.meta.contractStartDate
      this.lessor.meta.contractEndDate = data.meta.contractEndDate
      this.lessor.meta.rentalAmount = data.meta.rentalAmount
      this.lessor.meta.devicesNumber = data.meta.devicesNumber
      this.lessor.meta.pdv = data.meta.pdv
      this.lessor.meta.notificationEmail = data.meta.notificationEmail
      this.lessor.meta.paymentInsurance = data.meta.paymentInsurance
    })
  },
  methods: {
    onRentalTypeChange(event) {
      this.lessor.meta.rentalType = event.target.value
    },
    patchEntity() {
      ApiService.update('lessors', this.$route.params.id, this.lessor)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Lessor successfully updated in database.',
          })

          setTimeout(() => {
            this.$router.push({ name: 'lessors-list' })
          }, 500)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss"></style>
